/*

  /* Glowing circular background (Only on Hover) */
.glow-circle {
    /* width: 50px;
    height: 50px; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border-radius: 50%;
    /* margin-left:5px; */

    /* background: radial-gradient(circle, rgba(244, 95, 67, 0.6) 0%, rgba(30, 30, 58, 0) 80%); */
    /* box-shadow: 0 0 20px rgba(244, 95, 67, 0.6); */
    /* transition: all 0.3s ease-in-out; */
  }
  
  /* Left orange indicator (Only on Hover) */
  .left-indicator {
    position: absolute;
    left: -27px;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 37px;
    border-radius: 5px;
    background: linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%);
    transition: all 0.3s ease-in-out;
  }

  .bg-gradient {
    background: linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%);
    border-radius: 10px;
  }


  .open-sidebar-hover {
    background: linear-gradient(94.51deg, rgba(17, 15, 14, 0.6) -51.81%, rgba(10, 4, 1, 0.6) 101.41%);
    border-radius: 10px;
    backdrop-filter: blur(6.4px); /* Adds a blur effect */
  }
  

  .icon-effect1 {
    position: relative;
    /* width: 3rem; Adjust based on your icon size
    height: 3rem; Adjust based on your icon size */
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 50%; /* Makes it circular */
    background: linear-gradient(180deg, rgba(247, 244, 243, 0.6) 0% 8.19%, rgba(254, 253, 253, 0.6) 0%, rgba(244, 95, 67, 0.6) 99.06%);
    /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4); */
    /* box-shadow: -0.64px 0.64px 0.64px 0px #f3f0f0 inset; */

/* box-shadow: 0.64px -0.64px 0.64px 0px #f0f0f0 inset; */

backdrop-filter: blur(6.400000095367432px)

  }




  .icon-container{
    position: relative;
    width: 3rem; 
    height: 3rem; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-effect {
    position: relative;
    width: 3rem; 
    height: 3rem; 
    color:white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Makes it circular */
    background: linear-gradient(
      180deg,
      #1E1E3A 50.19%, 
      #1E1E3A 20.63%, 
      rgba(244, 95, 67, 0.6) 99.06%
    );

    /* background: linear-gradient(
      180deg,
      #1E1E3A 90.19%, 
      #1E1E3A 20.63%, 
      rgba(244, 95, 67, 0.6) 99.06%
    ); */
    /* Optional box-shadow effects */
        box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4);
    box-shadow: -0.64px 0.64px 0.64px 0px #f3f0f0 inset;

box-shadow: 0.64px -0.64px 0.64px 0px #f0f0f0 inset;

    backdrop-filter: blur(6.4px); /* Adds a blur effect */
  }

  .icon-effect-light {
    position: relative;
    width: 3rem; 
    height: 3rem; 
    color:white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Makes it circular */
    /* background: linear-gradient(
      180deg,
      #1E1E3A 50.19%, 
      #1E1E3A 20.63%, 
      rgba(244, 95, 67, 0.6) 99.06%
    ); */
    background: linear-gradient(94.51deg, #F45F43 -51.81%, #F89669 101.41%);

    /* background: linear-gradient(
      180deg,
      #1E1E3A 90.19%, 
      #1E1E3A 20.63%, 
      rgba(244, 95, 67, 0.6) 99.06%
    ); */
    /* Optional box-shadow effects */
        box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4);
    box-shadow: -0.64px 0.64px 0.64px 0px #f3f0f0 inset;

box-shadow: 0.64px -0.64px 0.64px 0px #f0f0f0 inset;

    backdrop-filter: blur(6.4px); /* Adds a blur effect */
  }


  .icon-hover{
    position: relative;
    width: 3rem; 
    height: 3rem; 
    display: flex;
    color:white !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Makes it circular */
    /* background: linear-gradient(
      180deg,
      #1E1E3A 50.19%, 
      #1E1E3A 20.63%, 
      rgba(244, 95, 67, 0.6) 99.06%
    ); */
    background: linear-gradient(
      180deg,
      #1E1E3A 90.19%, 
      #1E1E3A 20.63%, 
      rgba(244, 95, 67, 0.6) 100%
    );
    /* Optional box-shadow effects */
        box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4);
    box-shadow: -0.64px 0.64px 0.64px 0px #f3f0f0 inset;

box-shadow: 0.64px -0.64px 0.64px 0px #f0f0f0 inset;

    backdrop-filter: blur(6.4px); /* Adds a blur effect */
  }
  
/* Ensure toolbar text and icons are white */
.custom-quill .ql-toolbar {
  color: white !important;
  border-color: white !important;
  background-color: white !important;
}