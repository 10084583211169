/* Scrollbar for table only */
.MuiTableContainer-root::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #F45F43;
  border-radius: 8px;
}

.MuiTableContainer-root::-webkit-scrollbar-track {
  background: #151316;

}
